<template>
  <div>
    <v-data-table
      v-model="cobrosSelected"
      class="table-pagos elevation-1"
      checkbox-color="icono"
      :headers="headerPayment"
      :items="datos"
      :search="search"
      :loading="loadingData"
      :footer-props="{itemsPerPageText: 'Mostrar'}"
      show-expand
      sort-by="fecha_recibido"
      :sort-desc="true"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
    >
      <!-- show-select
      single-expand -->
      <!-- <template v-slot:header.data-table-select="{props}">
        <v-checkbox
          :indeterminate="props.indeterminate"
          :value="props.value"
          color="icono"
          hide-details
          class="pt-0 mt-0"
          @change="selectedAllCobros"
        />
      </template> -->
      <template v-slot:item.cod_pago="{item}">
        <div class="d-flex align-center" :class="{'py-8': !$vuetify.breakpoint.mobile }">
          <v-icon
            left
            :color="item.status === 0 ? 'warning' : item.status === 1 ? 'icono' : item.status === 2 ? 'red' : ''"
            v-text="item.status === 0 ? 'mdi-alert' : item.status === 1 ? 'mdi-checkbox-marked-circle' : item.status === 2 ?'mdi-close-circle-outline' : ''"
          />
          <span
            class="font-weight-medium"
            :class="{'warning--text': item.status === 0, 'icono--text': item.status === 1, 'red--text': item.status === 2 }"
            v-text="item.cod_pago"
          />
        </div>
      </template>
      <template v-slot:item.fecha_recibido="{item}">
        <span class="font-weight-medium blue-grey--text">{{item.fecha_recibido | smartDate}}</span>
      </template>
      <template v-slot:item.cli_des="{item}">
        <span
          class="primary--text font-weight-bold"
          :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }"
          v-text="item.cli_des" />
        <span
          class="font-weight-bold ml-1"
          v-text="'('+ item.co_cli + ')'" />
      </template>
      <template v-slot:item.ven_des="{item}">
        <span
          class="blue-grey--text font-weight-medium"
          :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }"
          v-text="item.ven_des" />
        <span
          class="font-weight-bold ml-1"
          v-text="'('+ item.co_ven + ')'" />
      </template>
      <template v-slot:item.monto="{item}">
        <div class="d-flex justify-center">
          <span class="font-weight-bold black--text" style="font-size:16px">${{ item.monto | formatNumero | FormatCurrency}}</span>
        </div>
      </template>
      <template v-slot:item.iconos="{item}">
        <div class="d-flex flex-row">
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  size="20"
                  color="blue-grey"
                  class="mx-2"
                  v-text="item.metodo_pago === 'efectivo' ? 'mdi-cash-multiple' : item.metodo_pago === 'transferencia' ? 'mdi-bank-transfer': 'mdi-cellphone-arrow-down'"
                />
              </template>
              <span>Metodo Pago: {{item.metodo_pago}}</span>
          </v-tooltip>
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="blue-grey--text font-weight-medium mx-2"
                  style="font-size: 18px"
                  v-text="item.moneda === 'dolares' ? '$' : item.moneda === 'bolivares' ? 'Bs.': ''"
                />
              </template>
              <span>Moneda: {{item.moneda}}</span>
          </v-tooltip>
        </div>
      </template>

      <!-- DETALLES DEL PAGO  -->
        <template v-slot:expanded-item="{item, headers}">
          <td :colspan="headers.length">
            <item-pago-detalles :pago="item" :key="`detailsPay_${item.cod_pago}`" @updateListaPagos="update" />
          </td>
        </template>
      <!-- FIN DETALLES  -->
      <template v-slot:footer.prepend>
        <v-btn
          color="primary"
          text
          small
        >
          <v-icon
            size="22"
            left
            v-text="'mdi-update'"
          />
          Actualizar Datos
        </v-btn>
      </template>
      <template #loading>
        <v-skeleton-loader
          type="table-tbody"
        ></v-skeleton-loader>
      </template>
      <template v-slot:no-data>
        <div class="text-center pa-4">
          <span class="blue-grey--text font-italic">No se encontraron Cobros Registrados</span>
        </div>
      </template>
      <template v-slot:footer="{items}">
        <v-row class="ma-0">
          <v-col cols="12" class="text-right blue-grey lighten-5">
            <span class="caption blue-grey--text text--darken-3 mb-1 mx-3 font-weight-medium text-uppercase">Monto Dolares: </span>
            <span
              class="text-h4 font-weight-bold blue-grey--text text--darken-3 mb-1 mx-3"
            >
              {{ montoDolares  | FormatCurrency }}$
            </span>
          </v-col>
        </v-row>
        <v-row class="ma-0 py-1">
          <v-col cols="12" class="text-right blue-grey lighten-5">
            <span class="caption blue-grey--text text--darken-3 mb-1 mx-3 font-weight-medium text-uppercase">Monto Bolivares: </span>
            <span
              class="text-h4 font-weight-bold blue-grey--text text--darken-3 mb-1 mx-3"
            >
              {{ montoBolivares | FormatCurrency }}Bs
            </span>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" class="text-right blue-grey lighten-4">
            <span class="caption blue-grey--text text--darken-3 mb-1 mx-3 font-weight-medium text-uppercase">Monto Total en Dolares: </span>
            <span
              class="text-h4 font-weight-bold blue-grey--text text--darken-3 mb-1 mx-3"
            >
              {{ montoTotalDolares | FormatCurrency }}$
            </span>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <v-bottom-sheet
      v-model="showActionStatus"
      hide-overlay
      inset
      persistent
      no-click-animation
      content-class="transparent elevation-0"
      dark
      :max-width="$vuetify.breakpoint.mdAndUp ? '55%' : '100%'"
    >
      <v-card class="mb-4">
        <v-row>
          <v-col
            cols="12"
            class="py-0 px-4 mt-1 d-flex justify-space-between"
          >
            <span class="caption grey--text text--lighten-1 ml-3 pt-1">Actualizar Estatus</span>
            <v-btn
              small
              icon
              plain
              @click="cancelStatus"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center align-center pt-1"
           :class="{'flex-row': $vuetify.breakpoint.smAndUp, 'flex-column': $vuetify.breakpoint.xsOnly}"
          >
            <template v-if="$vuetify.breakpoint.smAndUp">
              <div class="pr-3 caption">
                <v-icon size="20" left class="mt-n1">mdi-check</v-icon>
                <span class="font-weight-medium">{{cobrosSelected.length}} Seleccionados</span>
              </div>
              <v-divider vertical />
            </template>
            <v-btn-toggle
              v-model="statusMasive"
              dense
              group
              dark
              class="py-0 mx-3"
              active-class="rounded-lg elevation-2 selected-status"
            >
              <v-btn small value="1">
                <v-icon color="icono" left>mdi-checkbox-marked-circle</v-icon>
                <span class="font-weight-medium">Procesado</span>
              </v-btn>

              <v-btn small value="0">
                <v-icon color="warning" left>mdi-alert</v-icon>
                <span class="font-weight-medium">Sin Procesar</span>
              </v-btn>

              <v-btn small value="2">
                <v-icon color="red" left>mdi-close-circle-outline</v-icon>
                <span class="font-weight-medium">Anulado</span>
              </v-btn>
            </v-btn-toggle>
            <!-- <v-btn
              small
              color="blue-grey darkten-2"
              tile
              class="mr-2"
              :class="{'px-12 mt-3': $vuetify.breakpoint.xsOnly}"
              :loading="loadingStatus"
              @click="cancelStatus"
            >
              Cancelar
            </v-btn> -->
            <v-btn
              small
              color="secondary"
              tile
              :class="{'px-12 mt-3': $vuetify.breakpoint.xsOnly}"
              :loading="loadingStatus"
              :disabled="!statusMasive"
              @click="changeStatus"
            >
              Actualizar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>
<script>
export default {
  name: 'PagosData',
  components:{
    ItemPagoDetalles: () => import(
      /* webpackChunkName: "item-pago-detalles" */
      './ItemPagoDetalles.vue'
    )
  },
  props:{
    datos: {
      type: Array,
      default: () => ([])
    },
    search: String,
    loadingData: Boolean,
  },
  data: () => ({
    imagenes:{
      items: [],
      indexCurrent: 0
    },
    showImagenes: false,
    mode_lectura: true,
    updatePago:{
      tasa: '',
      status: '',
    },
    cobrosSelected: [],
    actionStatus: true,
    statusMasive: '',
    loadingStatus: false,
  }),
  computed: {
    showActionStatus() {
      return this.cobrosSelected.length > 0
    },
    headerPayment () {
      // { text: 'Seleccionar', value: 'data-table-select', class: this.$vuetify.breakpoint.smAndUp ? 'header-data' : '' },
      return [
        { text: 'Código', value: 'cod_pago', class: 'header-data' },
        { text: 'Recibido', value: 'fecha_recibido', class: 'header-data', width: '120'},
        { text: 'Cod Cliente', value: 'co_cli', class: 'header-data', width: '20', align: ' d-none'},
        { text: 'Cod Vendedor', value: 'co_ven', class: 'header-data', width: '20', align: ' d-none'},
        {
          text: 'Cliente',
          align: 'start',
          value: 'cli_des',
          class: 'header-data'
        },
        {
          text: 'Vendedor',
          align: 'start',
          value: 'ven_des',
          class: 'header-data',
          width: '220'
        },
        { text: 'Monto', value: 'monto', class: 'header-data px-0 d-flex justify-center align-center' },
        { text: '', value: 'iconos', class: 'header-data'},
        { text: '', value: 'data-table-expand', class: 'header-data' },
      ]
    },
    montoDolares() {
      return this.datos.reduce((monto, item) => {
        if(item.moneda !== 'dolares')
          return Number(monto)

        return Number(monto) + Number(item.monto)
      }, 0)
    },
    montoBolivares() {
      return this.datos.reduce((monto, item) => {
        if(item.moneda !== 'bolivares')
          return Number(monto)

        return Number(monto) + Number(item.monto_bs)
      }, 0)
    },
    montoTotalDolares() {
      return this.datos.reduce((monto, item) => {
        return Number(monto) + Number(item.monto)
      }, 0)
    }
  },
  watch:{
    showActionStatus(val) {
      if(val)
        this.statusMasive = ''
    }
  },
  methods:{
    update() {
       this.$emit('change');
    },
    montoEnDolares(pago) {
      if(pago.moneda === 'bolivares') {
        return pago.tasa !== null && pago.monto_bs !== 'null' && pago.monto_bs !== null && pago.monto_bs !== ''
          ? (parseFloat(pago.monto_bs) / parseFloat(pago.tasa)).toFixed(2).toString()
          : '0'
      }

      return pago.monto;
    },
    async changeStatus () {
      const confirmar = await this.$root.$confirm(
        '¿Estas Seguro?',
        'Se actualizará el estatus de los Cobros seleccionados',
        {
          type: 'confirm',
          btnConfirmText: 'Si, Actualizar',
          btnCancelText: 'Descartar',
        }
      );
      if (confirmar) {
        this.loadingStatus = true
        const cobros = this.cobrosSelected.map (pago => {
          return {
            id: pago.id,
            status: this.statusMasive,
          }
        })
        try {
          const { data } = await this.$api.post('pagos/update/masivo', { pagos: cobros });
          this.$emit('change');
          this.$root.$showAlert(
            `Se ha actualizado exitosamente el Estatus de los Cobros Seleccionados`,
            'success'
          );
          this.cobrosSelected = []
        } catch (error) {
          this.$root.$showAlert(
            `Lo sentimos, hubo un error al intentar actualizar el Estatus de los Cobros`,
            'error'
          );
        }
        this.loadingStatus = false
      }
    },
    cancelStatus () {
      this.cobrosSelected = []
    },
    selectedAllCobros(value) {
      this.cobrosSelected = value ? this.datos : []
    }
  },
  filters: {
    formatNumero(num) {
      return num !== null && num !== 'null' ? num.replace(/,/, '.') : '';
    }
  }
}
</script>
<style lang="sass">
.table-pagos
  & .v-data-table__wrapper > table > tbody > tr
    cursor: pointer
  &.v-data-table>.v-data-table__wrapper .v-data-table-header-mobile .v-data-table-header-mobile__wrapper .v-data-table-header-mobile__select::after
    content: 'Todos'
    margin-left: 5px
  &.v-data-table>.v-data-table__wrapper .v-data-table-header-mobile .v-data-table-header-mobile__wrapper .v-select
    margin-left: 20px

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content
  box-shadow: none !important

.selected-status.v-btn.v-btn
  background-color: rgba(67, 176, 42,0.12) !important
  border-color: rgba(67, 176, 42,0.12) important !important
  color: #FFF !important

</style>
